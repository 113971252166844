import { useState } from 'react';
import GifImage from '../../assets/images/shout-sticker.gif';
import CopyPasteImage from '../../assets/images/druk.png';
import * as clipboard from 'clipboard-polyfill';
// import { imgBytes32x32 } from './imgBytes32x32';
// import { imgBytes72x72 } from './imgBytes72x72';
// import { imgBytesIOS } from './imgBytesIOS';
// import { imgBytes } from './imgBytes';
import { svgBytes } from './svgBytes';

const h2 = 'font-light text-2xl';

export default function Home() {
  const [whenClicked, click] = useState<boolean>(false);

  function handleClick() {
    click(true);

    const blob = new Blob([new Uint8Array(svgBytes)], { type: 'image/png' });
    const items = [
      new clipboard.ClipboardItem({
        'image/png': blob,
      }),
    ];

    clipboard.write(items).then(
      function () {
        console.log('success!');
      },
      function (err) {
        console.log(err);
      },
    );
  }

  return (
    <div className="flex flex-col min-h-screen relative bg-primary ">
      <div className="bg-white text-black m-auto w-5/6 py-16 px-10 shadow-md rounded-xl | md:w-4/6 h-auto lg:w-5/12 xl:w-4/12 md:h-5/12">
        <div className="flex flex-row items-center">
          <div>
            <img width="50" height="50" src={GifImage} alt="druk emoji" />
          </div>
          <h1 className="text-4xl font-bold">Durk Emoji</h1>
        </div>
        <div className="pt-10">
          <h2 className={h2}>Lil Durk - The Voice Deluxe</h2>
        </div>
        <div className="mt-10 mb-12 | md:mb-0">
          <span>
            <span className="italic font-regular">Stream: </span>
            <a
              target="_blank"
              href="https://smarturl.it/TheVoiceDeluxe"
              className="underline text-blue-400"
            >
              The Voice Deluxe
            </a>
          </span>
        </div>
        <div className="flex flex-col justify-between items-center mt-3 | md:flex-row ">
          <h2 className={`${h2} mb-3 md:mb-0`}>Copy and paste this emoji:</h2>
          <div className="flex flex-row">
            <div className="border-gray-300 border p-1 px-2 rounded-md ml-auto mr-5">
              <img width="47" id="copy-image" src={CopyPasteImage} alt="mullet" />
            </div>
            <button
              className="font-light p-3  border border-gray-300 rounded-md"
              onClick={handleClick}
              onMouseDown={handleClick}
              onTouchEnd={handleClick}
              onTouchStart={handleClick}
            >
              {whenClicked ? 'Copied' : 'Copy'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
